import type { GetStaticProps, NextPage } from 'next'
import { HeadContents } from 'components/HeadContents'

const Page: NextPage = () => (
  <>
    <HeadContents title="ページが見つかりません" />
    <div>このページは存在しません。</div>
  </>
)

export default Page

export const getStaticProps: GetStaticProps = async () => {
  return {
    props: {
      layout: {
        plain: true,
      },
    },
  }
}
